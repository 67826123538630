export const locationsData = [
  {
    name: '서울특별시',
    subItems: [
      { name: '성내천', code: '1018A04', isChecked: false },
      { name: '양재천2', code: '1018A20', isChecked: false },
      { name: '탄천6', code: '1018A22', isChecked: false },
      { name: '뚝도', code: '1018A24', isChecked: false },
      { name: '중랑천4', code: '1018A32', isChecked: false },
      { name: '우이천', code: '1018A34', isChecked: false },
      { name: '중랑천5', code: '1018A36', isChecked: false },
      { name: '청계천1', code: '1018A38', isChecked: false },
      { name: '청계천2', code: '1018A40', isChecked: false },
      { name: '정릉천', code: '1018A42', isChecked: false },
      { name: '성북천', code: '1018A44', isChecked: false },
      { name: '청계천3', code: '1018A46', isChecked: false },
      { name: '중랑천6', code: '1018A48', isChecked: false },
      { name: '보광', code: '1018A50', isChecked: false },
      { name: '노량진(한강H)', code: '1018A52', isChecked: false },
      { name: '영등포', code: '1018A54', isChecked: false },
      { name: '홍제천', code: '1018A56', isChecked: false },
      { name: '목감천1', code: '1018A76', isChecked: false },
      { name: '안양천9(안양A)', code: '1018A82', isChecked: false },
      { name: '도림천', code: '1018A84', isChecked: false },
      { name: '안양천10', code: '1018A86', isChecked: false },
      { name: '한강', code: '서울특별시', isChecked: false },
      { name: '가양', code: '1019A05', isChecked: false },
      { name: '한강', code: '서울특별시', isChecked: false },
      { name: '고덕천', code: '1025A63', isChecked: false },
      { name: '암사', code: '1025A66', isChecked: false },
      { name: '구의(한강G)', code: '1025A69', isChecked: false },
      { name: '잠실', code: '1025A72', isChecked: false },
      { name: '초이천(한강H1)', code: '1025A57', isChecked: false },
      { name: '망월천(한강H2)', code: '1025A60', isChecked: false },
      { name: '감이천(한강H3)', code: '1018A02', isChecked: false },
      { name: '양재천1(한강H4)', code: '1018A18', isChecked: false },
      { name: '중랑천3(중랑A)', code: '1018A30', isChecked: false },
      { name: '안양천8(안양A2)', code: '1018A78', isChecked: false },
      { name: '목감천2(안양A6)', code: '1018A80', isChecked: false },
    ],
  },
  {
    name: '부산광역시',
    subItems: [
      { name: '금곡(낙본L)', code: '2022A30', isChecked: false },
      { name: '구포', code: '2022A35', isChecked: false },
      { name: '서낙동강1', code: '2022A40', isChecked: false },
      { name: '서낙동강2', code: '2022A45', isChecked: false },
      { name: '서낙동강3', code: '2022A50', isChecked: false },
      { name: '서낙동강4', code: '2022A60', isChecked: false },
      { name: '회동댐 상류', code: '2302A10', isChecked: false },
      { name: '수영강5', code: '2302A20', isChecked: false },
      { name: '낙동강 하구언1(낙본M)', code: '2022A38', isChecked: false },
      { name: '낙동강 하구언2(낙본N)', code: '2022A80', isChecked: false },
    ],
  },
  {
    name: '대구광역시',
    subItems: [
      { name: '성주', code: '2011A40', isChecked: false },
      { name: '다사', code: '2011A55', isChecked: false },
      { name: '달성', code: '2011A60', isChecked: false },
      { name: '금호강5', code: '2012A40', isChecked: false },
      { name: '금호강6', code: '2012A50', isChecked: false },
      { name: '금호강7', code: '2012A60', isChecked: false },
      { name: '금호강8(금호C)', code: '2012A70', isChecked: false },
      { name: '화원나루', code: '2014A10', isChecked: false },
      { name: '논공', code: '2014A25', isChecked: false },
      { name: '현풍', code: '2014A30', isChecked: false },
      { name: '차천', code: '2014A35', isChecked: false },
      { name: '대암리', code: '2014A50', isChecked: false },
      { name: '하빈천(낙본G1)', code: '2011A53', isChecked: false },
      { name: '욱수천(금호C1)', code: '2012A35', isChecked: false },
      { name: '금호강4(금호B)', code: '2012A38', isChecked: false },
    ],
  },
  {
    name: '인천광역시',
    subItems: [
      { name: '굴포천2', code: '1019A35', isChecked: false },
      { name: '굴포천4(굴포A)', code: '1019A40', isChecked: false },
      { name: '아라천1', code: '1019A65', isChecked: false },
      { name: '선행천', code: '1201A10', isChecked: false },
      { name: '내가천', code: '1201A20', isChecked: false },
      { name: '아라천2', code: '1201A40', isChecked: false },
      { name: '굴포천3(굴포A2)', code: '1019A37', isChecked: false },
    ],
  },
  {
    name: '광주광역시',
    subItems: [
      { name: '산동', code: '5001A27', isChecked: false },
      { name: '풍영정천2', code: '5001A30', isChecked: false },
      { name: '광주1', code: '5001A40', isChecked: false },
      { name: '광주천1', code: '5001A50', isChecked: false },
      { name: '광주천2', code: '5001A60', isChecked: false },
      { name: '광주2', code: '5001A70', isChecked: false },
      { name: '광주3(서창교)', code: '5001A80', isChecked: false },
      { name: '평림천', code: '5002A27', isChecked: false },
      { name: '황룡강4', code: '5002A30', isChecked: false },
      { name: '황룡강5', code: '5002A40', isChecked: false },
      { name: '황룡강3(황룡A)', code: '5002A25', isChecked: false },
      { name: '평동천', code: '5004A05', isChecked: false },
      { name: '장성천1(영본C2)', code: '5004A15', isChecked: false },
    ],
  },
  {
    name: '대전광역시',
    subItems: [
      { name: '주원천', code: '3008A30', isChecked: false },
      { name: '대청댐', code: '3008A40', isChecked: false },
      { name: '현도', code: '3008A60', isChecked: false },
      { name: '금곡천', code: '3009A10', isChecked: false },
      { name: '갑천1', code: '3009A15', isChecked: false },
      { name: '갑천2', code: '3009A20', isChecked: false },
      { name: '갑천3', code: '3009A25', isChecked: false },
      { name: '유등천2', code: '3009A35', isChecked: false },
      { name: '유등천3', code: '3009A40', isChecked: false },
      { name: '대전천1', code: '3009A45', isChecked: false },
      { name: '대전천2', code: '3009A50', isChecked: false },
      { name: '대전천3', code: '3009A55', isChecked: false },
      { name: '대전천4', code: '3009A60', isChecked: false },
      { name: '유등천7', code: '3009A62', isChecked: false },
      { name: '갑천4', code: '3009A65', isChecked: false },
      { name: '갑천5', code: '3009A70', isChecked: false },
      { name: '갑천6(갑천A)', code: '3009A80', isChecked: false },
      { name: '용호천(금본G1)', code: '3008A55', isChecked: false },
    ],
  },
  {
    name: '울산광역시',
    subItems: [
      { name: '덕현', code: '2201A03', isChecked: false },
      { name: '지헌', code: '2201A06', isChecked: false },
      { name: '신화', code: '2201A12', isChecked: false },
      { name: '반송', code: '2201A15', isChecked: false },
      { name: '대암', code: '2201A18', isChecked: false },
      { name: '보은천', code: '2201A21', isChecked: false },
      { name: '하잠교', code: '2201A24', isChecked: false },
      { name: '작동교', code: '2201A27', isChecked: false },
      { name: '대곡천1', code: '2201A31', isChecked: false },
      { name: '대곡천2', code: '2201A33', isChecked: false },
      { name: '망성', code: '2201A36', isChecked: false },
      { name: '구영', code: '2201A39', isChecked: false },
      { name: '삼호', code: '2201A42', isChecked: false },
      { name: '태화', code: '2201A45', isChecked: false },
      { name: '학성', code: '2201A48', isChecked: false },
      { name: '제일(A)', code: '2201A51', isChecked: false },
      { name: '신답', code: '2201A54', isChecked: false },
      { name: '동천', code: '2201A57', isChecked: false },
      { name: '내황', code: '2201A60', isChecked: false },
      { name: '명촌', code: '2201A63', isChecked: false },
      { name: '회야강1', code: '2301A20', isChecked: false },
      { name: '회야강2', code: '2301A30', isChecked: false },
      { name: '회야강3', code: '2301A40', isChecked: false },
      { name: '소호천(밀양A5)', code: '2021A05', isChecked: false },
    ],
  },
  {
    name: '세종특별자치시',
    subItems: [
      { name: '부강', code: '3010A20', isChecked: false },
      { name: '조천1', code: '3011A85', isChecked: false },
      { name: '월하천', code: '3011A90', isChecked: false },
      { name: '미호천10(미호C, 미호천)', code: '3011A97', isChecked: false },
      { name: '연기(금본H, 남면)', code: '3012A05', isChecked: false },
      { name: '용수천2', code: '3012A10', isChecked: false },
      { name: '대교천1', code: '3012A15', isChecked: false },
      { name: '세종1(금본G-1)', code: '3010A15', isChecked: false },
      { name: '백천(금본G2)', code: '3010A30', isChecked: false },
      { name: '미호천9(미호B)', code: '3011A84', isChecked: false },
      { name: '조천2(미호C1)', code: '3011A87', isChecked: false },
      { name: '대교천2', code: '3012A17', isChecked: false },
      { name: '세종2(금본H-1)', code: '3012A19', isChecked: false },
    ],
  },
  {
    name: '경기도',
    subItems: [
      {
        name: '청미천2',
        code: '1007A05',
        isChecked: false,
      },
      {
        name: '청미천4',
        code: '1007A10',
        isChecked: false,
      },
      {
        name: '청미천6(청미A, 청미천)',
        code: '1007A15',
        isChecked: false,
      },
      {
        name: '강천',
        code: '1007A20',
        isChecked: false,
      },
      {
        name: '금당천2',
        code: '1007A22',
        isChecked: false,
      },
      {
        name: '여주1',
        code: '1007A25',
        isChecked: false,
      },
      {
        name: '대신',
        code: '1007A27',
        isChecked: false,
      },
      {
        name: '여주2',
        code: '1007A40',
        isChecked: false,
      },
      {
        name: '양화천2',
        code: '1007A42',
        isChecked: false,
      },
      {
        name: '양화천4(양화A)',
        code: '1007A44',
        isChecked: false,
      },
      {
        name: '복하천2',
        code: '1007A46',
        isChecked: false,
      },
      {
        name: '복하천3',
        code: '1007A50',
        isChecked: false,
      },
      {
        name: '복하천5',
        code: '1007A55',
        isChecked: false,
      },
      {
        name: '곡수천2',
        code: '1007A59',
        isChecked: false,
      },
      {
        name: '이포(한강E)',
        code: '1007A60',
        isChecked: false,
      },
      {
        name: '흑천1',
        code: '1007A65',
        isChecked: false,
      },
      {
        name: '흑천3(흑천A)',
        code: '1007A70',
        isChecked: false,
      },
      {
        name: '강상',
        code: '1007A75',
        isChecked: false,
      },
      {
        name: '가평천1',
        code: '1013A70',
        isChecked: false,
      },
      {
        name: '가평천2',
        code: '1013A80',
        isChecked: false,
      },
      {
        name: '가평천3(가평A)',
        code: '1013A90',
        isChecked: false,
      },
      {
        name: '남이섬',
        code: '1013A95',
        isChecked: false,
      },
      {
        name: '조종천1',
        code: '1015A20',
        isChecked: false,
      },
      {
        name: '조종천2',
        code: '1015A25',
        isChecked: false,
      },
      {
        name: '조종천4(조종A)',
        code: '1015A30',
        isChecked: false,
      },
      {
        name: '대성리',
        code: '1015A40',
        isChecked: false,
      },
      {
        name: '구운천2',
        code: '1015A42',
        isChecked: false,
      },
      {
        name: '벽계천2',
        code: '1015A47',
        isChecked: false,
      },
      {
        name: '묵현천',
        code: '1015A50',
        isChecked: false,
      },
      {
        name: '삼봉리',
        code: '1015A60',
        isChecked: false,
      },
      {
        name: '경안천1',
        code: '1016A10',
        isChecked: false,
      },
      {
        name: '경안천2',
        code: '1016A20',
        isChecked: false,
      },
      {
        name: '경안천3',
        code: '1016A30',
        isChecked: false,
      },
      {
        name: '경안천5',
        code: '1016A40',
        isChecked: false,
      },
      {
        name: '경안천7',
        code: '1016A50',
        isChecked: false,
      },
      {
        name: '곤지암천3',
        code: '1016A60',
        isChecked: false,
      },
      {
        name: '경안천9',
        code: '1016A70',
        isChecked: false,
      },

      {
        name: '경안천11',
        code: '1016A80',
        isChecked: false,
      },

      {
        name: '팔당댐',
        code: '1017A10',
        isChecked: false,
      },
      {
        name: '탄천1',
        code: '1018A06',
        isChecked: false,
      },
      {
        name: '탄천3',
        code: '1018A12',
        isChecked: false,
      },
      {
        name: '탄천4',
        code: '1018A14',
        isChecked: false,
      },
      {
        name: '탄천5(탄천A)',
        code: '1018A16',
        isChecked: false,
      },
      {
        name: '중랑천2',
        code: '1018A28',
        isChecked: false,
      },
      {
        name: '안양천2',
        code: '1018A60',
        isChecked: false,
      },
      {
        name: '안양천4',
        code: '1018A66',
        isChecked: false,
      },
      {
        name: '안양천5',
        code: '1018A70',
        isChecked: false,
      },

      {
        name: '안양천6',
        code: '1018A72',
        isChecked: false,
      },

      {
        name: '창릉천3',
        code: '1019A10',
        isChecked: false,
      },
      {
        name: '창릉천4',
        code: '1019A15',
        isChecked: false,
      },
      {
        name: '창릉천5',
        code: '1019A20',
        isChecked: false,
      },
      {
        name: '행주(한강I)',
        code: '1019A25',
        isChecked: false,
      },
      {
        name: '김포',
        code: '1019A30',
        isChecked: false,
      },
      {
        name: '파주',
        code: '1019A45',
        isChecked: false,
      },
      {
        name: '공릉천2',
        code: '1019A50',
        isChecked: false,
      },
      {
        name: '공릉천4',
        code: '1019A55',
        isChecked: false,
      },
      {
        name: '공릉천5(공릉A)',
        code: '1019A60',
        isChecked: false,
      },
      {
        name: '임진강1',
        code: '1021A05',
        isChecked: false,
      },
      { name: '임진강2', code: '1021A10', isChecked: false },
      { name: '한탄강1(한탄A)', code: '1022A10', isChecked: false },
      { name: '영평천1', code: '1022A20', isChecked: false },
      { name: '영평천2', code: '1022A25', isChecked: false },
      { name: '포천천', code: '1022A30', isChecked: false },
      { name: '영평천3(영평A)', code: '1022A35', isChecked: false },
      { name: '한탄강2', code: '1022A37', isChecked: false },
      { name: '한탄강3', code: '1022A40', isChecked: false },
      { name: '신천1', code: '1022A45', isChecked: false },
      { name: '신천3', code: '1022A50', isChecked: false },
      { name: '신천5(신천A)', code: '1022A55', isChecked: false },
      { name: '차탄천', code: '1022A65', isChecked: false },
      { name: '한탄강4', code: '1022A70', isChecked: false },
      { name: '임진강4', code: '1023A10', isChecked: false },
      { name: '임진강5', code: '1023A20', isChecked: false },
      { name: '임진강7', code: '1023A25', isChecked: false },
      { name: '문산천2', code: '1023A30', isChecked: false },
      { name: '문산천3', code: '1023A40', isChecked: false },
      { name: '문산천4(문산A)', code: '1023A50', isChecked: false },
      { name: '월곶', code: '1024A10', isChecked: false },
      { name: '팔당(한강F)', code: '1025A03', isChecked: false },
      { name: '산곡천', code: '1025A06', isChecked: false },
      { name: '덕풍천', code: '1025A09', isChecked: false },
      { name: '도곡', code: '1025A12', isChecked: false },
      { name: '궁촌천', code: '1025A15', isChecked: false },
      { name: '도심천', code: '1025A18', isChecked: false },
      { name: '월문천', code: '1025A21', isChecked: false },
      { name: '덕소천', code: '1025A24', isChecked: false },
      { name: '홍릉천', code: '1025A27', isChecked: false },
      { name: '왕숙천2', code: '1025A36', isChecked: false },
      { name: '왕숙천3', code: '1025A39', isChecked: false },
      { name: '왕숙천5', code: '1025A48', isChecked: false },
      { name: '왕숙천6(왕숙A)', code: '1025A51', isChecked: false },
      { name: '구리', code: '1025A54', isChecked: false },
      { name: '안성천1', code: '1101A05', isChecked: false },
      { name: '안성천2', code: '1101A10', isChecked: false },
      { name: '안성천3', code: '1101A25', isChecked: false },
      { name: '진위천1(진위A1)', code: '1101A27', isChecked: false },
      { name: '성은천(진위A2)', code: '1101A28', isChecked: false },
      { name: '진위천2(진위A3)', code: '1101A29', isChecked: false },
      { name: '진위천3', code: '1101A30', isChecked: false },
      { name: '오산천1', code: '1101A35', isChecked: false },
      { name: '오산천2(진위A4)', code: '1101A37', isChecked: false },
      { name: '오산천3', code: '1101A40', isChecked: false },
      { name: '오산천4(진위A5)', code: '1101A42', isChecked: false },
      { name: '오산천5', code: '1101A45', isChecked: false },
      { name: '오산천6(진위A6)', code: '1101A47', isChecked: false },
      { name: '오산천7', code: '1101A50', isChecked: false },
      { name: '한강', code: '경기도', isChecked: false },
      { name: '진위천4', code: '1101A55', isChecked: false },
      { name: '황구지천1(진위A7)', code: '1101A57', isChecked: false },
      { name: '황구지천2(진위A8)', code: '1101A58', isChecked: false },
      { name: '황구지천3', code: '1101A60', isChecked: false },
      { name: '황구지천4(진위A9)', code: '1101A62', isChecked: false },
      { name: '황구지천5(진위A10)', code: '1101A65', isChecked: false },
      { name: '황구지천6(진위A11)', code: '1101A70', isChecked: false },
      { name: '진위천5(진위A12)', code: '1101A72', isChecked: false },
      { name: '관리천(진위A13)', code: '1101A73', isChecked: false },
      { name: '진위천6(진위A)', code: '1101A75', isChecked: false },
      { name: '반월천', code: '1202A10', isChecked: false },
      { name: '한강', code: '경기도', isChecked: false },
      { name: '오갑천(청미A1-1)', code: '1007A01', isChecked: false },
      { name: '청미천1(청미A1)', code: '1007A03', isChecked: false },
      { name: '청미천3(청미A2)', code: '1007A11', isChecked: false },
      { name: '청미천5(청미A3)', code: '1007A12', isChecked: false },
      { name: '응천(청미A4)', code: '1007A14', isChecked: false },
      { name: '금당천1(한강E1)', code: '1007A21', isChecked: false },
      { name: '양화천1(양화A1)', code: '1007A41', isChecked: false },
      { name: '양화천3(양화A1-1)', code: '1007A43', isChecked: false },
      { name: '복하천1(복하A1)', code: '1007A45', isChecked: false },
      { name: '복하천4(복하A1-1)', code: '1007A52', isChecked: false },
      { name: '복하천6(복하A, 복하천)', code: '1007A57', isChecked: false },
      { name: '곡수천1(한강E1-1)', code: '1007A58', isChecked: false },
      { name: '금사천(한강E2-1)', code: '1007A61', isChecked: false },
      { name: '용담천(한강E2-2)', code: '1007A63', isChecked: false },
      { name: '흑천2(흑천A1-1)', code: '1007A67', isChecked: false },
      { name: '사탄천(한강F1-1)', code: '1007A68', isChecked: false },
      { name: '관대천(한강F1-1)', code: '1007A71', isChecked: false },
      { name: '강릉강', code: '1061A10', isChecked: false },
      { name: '청평천', code: '1061A12', isChecked: false },
    ],
  },
  {
    name: '강원도',
    subItems: [
      {
        name: '송천1',
        code: '1001A05',
        isChecked: false,
      },

      {
        name: '송천4',
        code: '1001A10',
        isChecked: false,
      },

      {
        name: '골지천2',
        code: '1001A15',
        isChecked: false,
      },

      {
        name: '번천',
        code: '1001A20',
        isChecked: false,
      },

      {
        name: '골지천4',
        code: '1001A25',
        isChecked: false,
      },

      {
        name: '조양강(골지A)',
        code: '1001A30',
        isChecked: false,
      },

      {
        name: '오대천1',
        code: '1001A35',
        isChecked: false,
      },

      {
        name: '오대천3(오대A)',
        code: '1001A40',
        isChecked: false,
      },

      {
        name: '정선1',
        code: '1001A45',
        isChecked: false,
      },

      {
        name: '어천2',
        code: '1001A50',
        isChecked: false,
      },

      {
        name: '정선2',
        code: '1001A55',
        isChecked: false,
      },

      {
        name: '광하',
        code: '1001A60',
        isChecked: false,
      },

      {
        name: '지장천1',
        code: '1001A65',
        isChecked: false,
      },

      {
        name: '지장천2',
        code: '1001A70',
        isChecked: false,
      },

      {
        name: '동강2',
        code: '1001A75',
        isChecked: false,
      },

      {
        name: '석항천',
        code: '1001A80',
        isChecked: false,
      },

      {
        name: '영월1',
        code: '1001A85',
        isChecked: false,
      },

      {
        name: '주천강1',
        code: '1002A10',
        isChecked: false,
      },

      {
        name: '주천강3(주천A)',
        code: '1002A20',
        isChecked: false,
      },

      {
        name: '평창강1',
        code: '1002A30',
        isChecked: false,
      },

      {
        name: '평창강2',
        code: '1002A40',
        isChecked: false,
      },

      {
        name: '평창강4',
        code: '1002A50',
        isChecked: false,
      },

      {
        name: '평창강5(평창A)',
        code: '1002A55',
        isChecked: false,
      },

      {
        name: '영월2',
        code: '1003A05',
        isChecked: false,
      },

      {
        name: '옥동천2',
        code: '1003A10',
        isChecked: false,
      },

      {
        name: '옥동천3(옥동A)',
        code: '1003A15',
        isChecked: false,
      },

      {
        name: '운계천',
        code: '1005A55',
        isChecked: false,
      },

      {
        name: '원주(한강D)',
        code: '1005A60',
        isChecked: false,
      },

      {
        name: '유동천1',
        code: '1006A05',
        isChecked: false,
      },

      {
        name: '유동천2',
        code: '1006A10',
        isChecked: false,
      },

      {
        name: '계천1',
        code: '1006A15',
        isChecked: false,
      },

      {
        name: '대관대천',
        code: '1006A20',
        isChecked: false,
      },

      {
        name: '계천2',
        code: '1006A25',
        isChecked: false,
      },

      {
        name: '금계천2',
        code: '1006A30',
        isChecked: false,
      },

      {
        name: '전천',
        code: '1006A35',
        isChecked: false,
      },

      {
        name: '섬강2',
        code: '1006A40',
        isChecked: false,
      },

      {
        name: '섬강3(섬강A)',
        code: '1006A45',
        isChecked: false,
      },

      {
        name: '원주천1',
        code: '1006A50',
        isChecked: false,
      },

      {
        name: '원주천2',
        code: '1006A55',
        isChecked: false,
      },

      {
        name: '섬강4',
        code: '1006A60',
        isChecked: false,
      },

      {
        name: '서곡천',
        code: '1006A65',
        isChecked: false,
      },

      {
        name: '섬강5',
        code: '1006A70',
        isChecked: false,
      },

      {
        name: '섬강6(섬강B, 강천)',
        code: '1006A80',
        isChecked: false,
      },

      {
        name: '화천(북한A)',
        code: '1009A10',
        isChecked: false,
      },

      {
        name: '양구서천',
        code: '1010A10',
        isChecked: false,
      },

      {
        name: '화천천',
        code: '1010A30',
        isChecked: false,
      },

      {
        name: '춘천2',
        code: '1010A40',
        isChecked: false,
      },

      {
        name: '춘천3',
        code: '1010A50',
        isChecked: false,
      },

      {
        name: '북천',
        code: '1011A10',
        isChecked: false,
      },

      {
        name: '인북천1(인북A)',
        code: '1011A20',
        isChecked: false,
      },

      {
        name: '인북천2',
        code: '1011A30',
        isChecked: false,
      },

      {
        name: '내린천2(소양A)',
        code: '1012A20',
        isChecked: false,
      },

      {
        name: '소양강1',
        code: '1012A30',
        isChecked: false,
      },

      {
        name: '소양강2(소양B)',
        code: '1012A40',
        isChecked: false,
      },

      {
        name: '공지천1',
        code: '1013A05',
        isChecked: false,
      },

      {
        name: '공지천2',
        code: '1013A10',
        isChecked: false,
      },

      {
        name: '공지천3',
        code: '1013A20',
        isChecked: false,
      },

      {
        name: '의암댐',
        code: '1013A40',
        isChecked: false,
      },

      {
        name: '의암',
        code: '1013A50',
        isChecked: false,
      },

      {
        name: '춘성교(북한C)',
        code: '1013A60',
        isChecked: false,
      },

      {
        name: '화양강',
        code: '1014A10',
        isChecked: false,
      },

      {
        name: '홍천강1',
        code: '1014A20',
        isChecked: false,
      },

      {
        name: '홍천강2',
        code: '1014A30',
        isChecked: false,
      },

      {
        name: '홍천강3(홍천A)',
        code: '1014A70',
        isChecked: false,
      },

      {
        name: '김화 남대천',
        code: '1022A05',
        isChecked: false,
      },

      {
        name: '간성북천',
        code: '1301A10',
        isChecked: false,
      },

      {
        name: '용천',
        code: '1301A20',
        isChecked: false,
      },

      {
        name: '양양',
        code: '1301A30',
        isChecked: false,
      },

      {
        name: '쌍촌',
        code: '1302A10',
        isChecked: false,
      },

      {
        name: '강릉',
        code: '1302A20',
        isChecked: false,
      },

      {
        name: '홍천',
        code: '1303A05',
        isChecked: false,
      },

      {
        name: '남강',
        code: '1303A20',
        isChecked: false,
      },

      {
        name: '지장',
        code: '1304A10',
        isChecked: false,
      },

      {
        name: '주천강',
        code: '1305A10',
        isChecked: false,
      },

      {
        name: '세미천',
        code: '1305A20',
        isChecked: false,
      },

      {
        name: '청평천',
        code: '1305A30',
        isChecked: false,
      },

      {
        name: '청송군1',
        code: '1305A40',
        isChecked: false,
      },
    ],
  },
  {
    name: '충청북도',
    subItems: [
      { name: '가곡', code: '1003A20', isChecked: false },
      { name: '가대천', code: '1003A25', isChecked: false },
      { name: '덕천', code: '1003A30', isChecked: false },
      { name: '매포천', code: '1003A40', isChecked: false },
      { name: '죽령천', code: '1003A45', isChecked: false },
      { name: '장평천', code: '1003A48', isChecked: false },
      { name: '제천천2', code: '1003A50', isChecked: false },
      { name: '제천천3', code: '1003A55', isChecked: false },
      { name: '원서천1', code: '1003A65', isChecked: false },
      { name: '제천천5', code: '1003A70', isChecked: false },

      { name: '충주댐', code: '1003A74', isChecked: false },

      { name: '충주(한강C)', code: '1003A75', isChecked: false },
      { name: '목행대교', code: '1003A85', isChecked: false },
      { name: '달천2', code: '1004A05', isChecked: false },
      { name: '화양천', code: '1004A10', isChecked: false },
      { name: '향목동천', code: '1004A15', isChecked: false },
      { name: '쌍천', code: '1004A20', isChecked: false },
      { name: '달천4', code: '1004A25', isChecked: false },
      { name: '동진천1', code: '1004A30', isChecked: false },
      { name: '동진천2', code: '1004A35', isChecked: false },
      { name: '달천5', code: '1004A40', isChecked: false },
      { name: '음성천2', code: '1004A42', isChecked: false },
      { name: '석문동천1', code: '1004A50', isChecked: false },
      { name: '석문동천2', code: '1004A55', isChecked: false },

      { name: '달천7', code: '1004A60', isChecked: false },

      { name: '요도천', code: '1004A65', isChecked: false },
      { name: '충주천', code: '1004A67', isChecked: false },
      { name: '달천8(달천B)', code: '1004A70', isChecked: false },
      { name: '영덕천', code: '1005A20', isChecked: false },
      { name: '중원', code: '1005A30', isChecked: false },
      { name: '앙성천', code: '1005A40', isChecked: false },
      { name: '덕은리', code: '1005A50', isChecked: false },
      { name: '영동', code: '3004A50', isChecked: false },

      { name: '영동천1', code: '3004A60', isChecked: false },
      { name: '영동천2', code: '3004A70', isChecked: false },

      { name: '추풍령천', code: '3005A10', isChecked: false },
      { name: '초강1', code: '3005A20', isChecked: false },
      { name: '석천', code: '3005A27', isChecked: false },
      { name: '초강2(초강A)', code: '3005A30', isChecked: false },
      { name: '옥천', code: '3006A10', isChecked: false },

      { name: '우산', code: '3006A20', isChecked: false },

      { name: '보청천1', code: '3007A10', isChecked: false },
      { name: '보청천2', code: '3007A20', isChecked: false },
      { name: '항건천', code: '3007A30', isChecked: false },
      { name: '삼가천', code: '3007A35', isChecked: false },
      { name: '보청천3(보청A)', code: '3007A40', isChecked: false },
      { name: '보청천4', code: '3007A50', isChecked: false },
      { name: '옥천천', code: '3008A10', isChecked: false },
      { name: '회인천', code: '3008A20', isChecked: false },
      { name: '품곡천', code: '3008A50', isChecked: false },
      { name: '미호천1', code: '3011A05', isChecked: false },
      { name: '칠장천', code: '3011A10', isChecked: false },
      { name: '미호천3', code: '3011A20', isChecked: false },
      { name: '백곡천1', code: '3011A25', isChecked: false },
      { name: '백곡천2', code: '3011A30', isChecked: false },
      { name: '미호천4', code: '3011A32', isChecked: false },
      { name: '초평천', code: '3011A35', isChecked: false },
      { name: '미호천5(미호A)', code: '3011A40', isChecked: false },
      { name: '보강천2', code: '3011A45', isChecked: false },
      { name: '미호천6', code: '3011A50', isChecked: false },
      { name: '무심천2', code: '3011A60', isChecked: false },
      { name: '무심천3(무심A)', code: '3011A65', isChecked: false },
      { name: '미호천7', code: '3011A70', isChecked: false },

      { name: '병천천2', code: '3011A80', isChecked: false },
      { name: '미호천8', code: '3011A82', isChecked: false },
      { name: '무도천(평창A2)', code: '1002A48', isChecked: false },
      { name: '오사리(한강B1)', code: '1003A16', isChecked: false },
      { name: '영춘(한강A, 단양)', code: '1003A17', isChecked: false },

      { name: '단성(한강B)', code: '1003A47', isChecked: false },
      { name: '제천천1(제천A1)', code: '1003A49', isChecked: false },
      { name: '원서천2(제천A2)', code: '1003A68', isChecked: false },
      { name: '제천천4(제천A)', code: '1003A71', isChecked: false },
      { name: '동달천(한강C1)', code: '1003A72', isChecked: false },
      { name: '달천1(달천A1)', code: '1004A03', isChecked: false },
      { name: '달천3(달천A2)', code: '1004A07', isChecked: false },
      { name: '구룡천(달천A3)', code: '1004A09', isChecked: false },
      { name: '음성천1(달천A4)', code: '1004A41', isChecked: false },
      { name: '달천6(달천B2)', code: '1004A56', isChecked: false },
      { name: '미호천(미호A1)', code: '3011A53', isChecked: false },
      { name: '미호천(미호A2)', code: '3011A55', isChecked: false },
    ],
  },
  {
    name: '전라북도',
    subItems: [
      {
        name: '람천1',
        code: '2018A20',
        isChecked: false,
      },
      {
        name: '람천2',
        code: '2018A25',
        isChecked: false,
      },
      {
        name: '가막(금본A)',
        code: '3001A10',
        isChecked: false,
      },
      {
        name: '진안천',
        code: '3001A20',
        isChecked: false,
      },
      {
        name: '용포',
        code: '3002A50',
        isChecked: false,
      },
      {
        name: '무주남대천1',
        code: '3003A10',
        isChecked: false,
      },
      {
        name: '무주남대천2',
        code: '3003A20',
        isChecked: false,
      },
      {
        name: '마산천',
        code: '3013A70',
        isChecked: false,
      },
      {
        name: '고산',
        code: '3301A05',
        isChecked: false,
      },
      {
        name: '소양천1',
        code: '3301A10',
        isChecked: false,
      },
      {
        name: '소양천2',
        code: '3301A15',
        isChecked: false,
      },
      {
        name: '전주(만경A)',
        code: '3301A20',
        isChecked: false,
      },
      {
        name: '전주천1',
        code: '3301A25',
        isChecked: false,
      },
      {
        name: '삼천1',
        code: '3301A30',
        isChecked: false,
      },
      {
        name: '삼천2',
        code: '3301A35',
        isChecked: false,
      },
      {
        name: '삼천3',
        code: '3301A37',
        isChecked: false,
      },
      {
        name: '전주천7(전주A)',
        code: '3301A40',
        isChecked: false,
      },
      {
        name: '삼례',
        code: '3301A45',
        isChecked: false,
      },
      {
        name: '익산천1',
        code: '3301A51',
        isChecked: false,
      },
      {
        name: '익산천2',
        code: '3301A52',
        isChecked: false,
      },
      {
        name: '익산천3',
        code: '3301A53',
        isChecked: false,
      },
      {
        name: '마산천',
        code: '3301A54',
        isChecked: false,
      },
      {
        name: '김제(만경B)',
        code: '3301A55',
        isChecked: false,
      },
      {
        name: '용암천',
        code: '3301A57',
        isChecked: false,
      },
      {
        name: '동진강1',
        code: '3302A05',
        isChecked: false,
      },
      {
        name: '용호천',
        code: '3302A07',
        isChecked: false,
      },
      {
        name: '동진강2',
        code: '3302A10',
        isChecked: false,
      },
      {
        name: '정읍천1',
        code: '3302A25',
        isChecked: false,
      },
      {
        name: '정읍천2',
        code: '3302A30',
        isChecked: false,
      },
      {
        name: '천원천',
        code: '3302A32',
        isChecked: false,
      },
      {
        name: '정읍천3',
        code: '3302A33',
        isChecked: false,
      },
      {
        name: '정읍천4(정읍A)',
        code: '3302A35',
        isChecked: false,
      },
      {
        name: '동진강3(동진A)',
        code: '3302A40',
        isChecked: false,
      },
      {
        name: '고부천1',
        code: '3302A45',
        isChecked: false,
      },
      {
        name: '고부천2',
        code: '3302A50',
        isChecked: false,
      },
      {
        name: '원평천1',
        code: '3302A55',
        isChecked: false,
      },
      {
        name: '원평천2',
        code: '3302A60',
        isChecked: false,
      },
      {
        name: '신평천',
        code: '3302A70',
        isChecked: false,
      },
      {
        name: '백천내',
        code: '3303A10',
        isChecked: false,
      },
      {
        name: '관촌(섬본A)',
        code: '4001A10',
        isChecked: false,
      },
      {
        name: '운암',
        code: '4001A30',
        isChecked: false,
      },
      {
        name: '임실(섬본B)',
        code: '4002A20',
        isChecked: false,
      },
      {
        name: '동계',
        code: '4002A40',
        isChecked: false,
      },
      {
        name: '오수천2(오수A)',
        code: '4003A10',
        isChecked: false,
      },
      {
        name: '적성',
        code: '4004A10',
        isChecked: false,
      },
      {
        name: '남원',
        code: '4004A20',
        isChecked: false,
      },
      {
        name: '요천2',
        code: '4005A10',
        isChecked: false,
      },
      {
        name: '요천3 (요천B)',
        code: '4005A20',
        isChecked: false,
      },
      {
        name: '주진천1',
        code: '5301A10',
        isChecked: false,
      },
      {
        name: '주진천2',
        code: '5301A20',
        isChecked: false,
      },
      {
        name: '구량천(금본B1)',
        code: '3001A05',
        isChecked: false,
      },
      {
        name: '정자천(금본B2)',
        code: '3001A30',
        isChecked: false,
      },
      {
        name: '용담(금본B)',
        code: '3002A40',
        isChecked: false,
      },
      {
        name: '무주남대천3(금본C1)',
        code: '3003A30',
        isChecked: false,
      },
      {
        name: '산북천',
        code: '3014A15',
        isChecked: false,
      },
      {
        name: '탑천(탑천A)',
        code: '3301A60',
        isChecked: false,
      },
      {
        name: '고부천3(고부A)',
        code: '3302A52',
        isChecked: false,
      },
      {
        name: '원평천3(원평A)',
        code: '3302A65',
        isChecked: false,
      },
      {
        name: '추령천(추령A)',
        code: '4001A20',
        isChecked: false,
      },
      {
        name: '치천(섬본C1)',
        code: '4002A25',
        isChecked: false,
      },
      {
        name: '오수천1(오수A1)',
        code: '4003A02',
        isChecked: false,
      },
      {
        name: '호천(호천A)',
        code: '5301A35',
        isChecked: false,
      },
    ],
  },
  {
    name: '전라남도',
    subItems: [
      { name: '고달', code: '4006A10', isChecked: false },
      { name: '곡성', code: '4006A20', isChecked: false },
      { name: '보성강3', code: '4007A10', isChecked: false },
      { name: '보성강4', code: '4007A15', isChecked: false },
      { name: '동복천2(동복A)', code: '4007A30', isChecked: false },
      { name: '동복천3', code: '4007A35', isChecked: false },
      { name: '주암댐', code: '4007A40', isChecked: false },
      { name: '보성강6', code: '4008A10', isChecked: false },
      { name: '보성강7', code: '4008A20', isChecked: false },
      { name: '계산(섬본D)', code: '4009A20', isChecked: false },
      { name: '구례(구례)', code: '4009A30', isChecked: false },
      { name: '진월', code: '4009A90', isChecked: false },
      { name: '강진천', code: '4101A10', isChecked: false },
      { name: '군외천', code: '4102A10', isChecked: false },
      { name: '오천천', code: '4103A10', isChecked: false },
      { name: '이사천1', code: '4104A10', isChecked: false },
      { name: '이사천3', code: '4104A20', isChecked: false },
      { name: '순천동천1', code: '4104A30', isChecked: false },
      { name: '순천동천2', code: '4104A40', isChecked: false },
      { name: '순천동천3', code: '4104A50', isChecked: false },
      { name: '수어천1', code: '4105A10', isChecked: false },
      { name: '광양서천', code: '4105A20', isChecked: false },
      { name: '수어천2', code: '4105A40', isChecked: false },
      { name: '돌산천', code: '4106A10', isChecked: false },
      { name: '담양', code: '5001A10', isChecked: false },
      { name: '황룡강1', code: '5002A10', isChecked: false },
      { name: '황룡강2', code: '5002A20', isChecked: false },
      { name: '지석천1', code: '5003A10', isChecked: false },
      { name: '화순천', code: '5003A20', isChecked: false },
      { name: '지석천3', code: '5003A30', isChecked: false },
      { name: '지석천4', code: '5003A60', isChecked: false },
      { name: '광산(영본B, 용봉)', code: '5004A10', isChecked: false },
      { name: '나주', code: '5004A20', isChecked: false },
      { name: '영산포1', code: '5004A30', isChecked: false },
      { name: '죽산(나주)', code: '5004A35', isChecked: false },
      { name: '영산포2(영본C)', code: '5004A40', isChecked: false },
      { name: '고막원천2', code: '5005A15', isChecked: false },
      { name: '고막원천3', code: '5005A20', isChecked: false },
      { name: '고막원천4', code: '5005A30', isChecked: false },
      { name: '함평천1', code: '5006A10', isChecked: false },
      { name: '함평천2', code: '5006A20', isChecked: false },
      { name: '함평(영본D)', code: '5006A35', isChecked: false },
      { name: '무안1', code: '5006A40', isChecked: false },
      { name: '영암천1', code: '5007A10', isChecked: false },
      { name: '무안2(영본E)', code: '5008A10', isChecked: false },
      { name: '탐진강2', code: '5101A10', isChecked: false },
      { name: '유치천2', code: '5101A20', isChecked: false },
      { name: '옴천천1', code: '5101A30', isChecked: false },
      { name: '탐진강3', code: '5101A35', isChecked: false },
      { name: '탐진강4(탐진A)', code: '5101A40', isChecked: false },
      { name: '금강천2', code: '5101A50', isChecked: false },
      { name: '탐진강5(탐진B)', code: '5101A55', isChecked: false },
      { name: '석교천', code: '5201A10', isChecked: false },
      { name: '와탄천', code: '5302A20', isChecked: false },
      { name: '금산천', code: '5303A10', isChecked: false },
      { name: '석곡천(영본A1)', code: '5001A15', isChecked: false },
      { name: '용산천(영본B1)', code: '5001A23', isChecked: false },
      { name: '진원천(영본B2)', code: '5001A26', isChecked: false },
      { name: '풍영정천1(영본B3)', code: '5001A28', isChecked: false },
      { name: '북하천(황룡A1)', code: '5002A05', isChecked: false },
      { name: '봉구천(용봉A1)', code: '5003A07', isChecked: false },
    ],
  },
  {
    name: '경상북도',
    subItems: [
      { name: '석포3', code: '2001A36', isChecked: false },
      { name: '석포4', code: '2001A37', isChecked: false },
      { name: '봉화', code: '2001A40', isChecked: false },
      { name: '도산(낙본B)', code: '2001A50', isChecked: false },
      { name: '안동1(안동댐하류)', code: '2001A60', isChecked: false },
      { name: '석포1', code: '2001A70', isChecked: false },
      { name: '석포2', code: '2001A80', isChecked: false },
      { name: '반변천1', code: '2002A10', isChecked: false },
      { name: '용전천1(용전A)', code: '2002A20', isChecked: false },
      { name: '용전천3', code: '2002A30', isChecked: false },
      { name: '보현천', code: '2002A34', isChecked: false },
      { name: '길안천2', code: '2002A37', isChecked: false },
      { name: '반변천3', code: '2002A40', isChecked: false },
      { name: '반변천4(반변B)', code: '2002A50', isChecked: false },
      { name: '안동2', code: '2003A10', isChecked: false },
      { name: '미천(미천A)', code: '2003A20', isChecked: false },
      { name: '안동3', code: '2003A30', isChecked: false },
      { name: '안동4', code: '2003A40', isChecked: false },
      { name: '예천1', code: '2003A50', isChecked: false },
      { name: '예천2', code: '2003A60', isChecked: false },
      { name: '내성천1', code: '2004A10', isChecked: false },
      { name: '영주서천1', code: '2004A20', isChecked: false },
      { name: '영주서천2', code: '2004A30', isChecked: false },
      { name: '내성천2(내성A)', code: '2004A40', isChecked: false },
      { name: '내성천3', code: '2004A50', isChecked: false },
      { name: '내성천4', code: '2004A70', isChecked: false },
      { name: '내성천5(내성B)', code: '2004A90', isChecked: false },
      { name: '영강1', code: '2005A10', isChecked: false },
      { name: '이안천(이안A)', code: '2005A15', isChecked: false },
      { name: '영강2', code: '2005A20', isChecked: false },
      { name: '영강3(영강A)', code: '2005A30', isChecked: false },
      { name: '병성천(병성A)', code: '2006A20', isChecked: false },
      { name: '영순', code: '2007A10', isChecked: false },
      { name: '상주1', code: '2007A20', isChecked: false },
      { name: '도남', code: '2007A25', isChecked: false },
      { name: '상주2(낙단U, 신암)', code: '2007A30', isChecked: false },
      { name: '위천1', code: '2008A03', isChecked: false },
      { name: '군위남천', code: '2008A05', isChecked: false },
      { name: '위천2', code: '2008A10', isChecked: false },
      { name: '쌍계천', code: '2008A20', isChecked: false },
      { name: '위천4', code: '2008A30', isChecked: false },
      { name: '위천5(위천B)', code: '2008A40', isChecked: false },
      { name: '낙단', code: '2009A05', isChecked: false },
      { name: '상주3(낙본D)', code: '2009A10', isChecked: false },
      { name: '산곡(선산U, 도개)', code: '2009A20', isChecked: false },
      { name: '선산', code: '2009A30', isChecked: false },
      { name: '부항천', code: '2010A05', isChecked: false },
      { name: '감천1', code: '2010A10', isChecked: false },
      { name: '감천3', code: '2010A20', isChecked: false },
      { name: '감천4(감천)', code: '2010A30', isChecked: false },
      { name: '강정(해평)', code: '2011A10', isChecked: false },
      { name: '구미(낙본E, 칠곡)', code: '2011A20', isChecked: false },
      { name: '칠곡', code: '2011A25', isChecked: false },
      { name: '왜관', code: '2011A30', isChecked: false },
      { name: '백천2', code: '2011A50', isChecked: false },
      { name: '죽장천', code: '2012A10', isChecked: false },
      { name: '금호강1', code: '2012A20', isChecked: false },
      { name: '고현천', code: '2012A21', isChecked: false },
      { name: '금호강3', code: '2012A30', isChecked: false },
      { name: '회천1', code: '2013A10', isChecked: false },
      { name: '회천2', code: '2013A30', isChecked: false },
      { name: '회천3(회천A)', code: '2013A40', isChecked: false },
      { name: '고령(고령)', code: '2014A20', isChecked: false },
      { name: '동창천1(밀양A2)', code: '2021A12', isChecked: false },
      { name: '청도천', code: '2021A20', isChecked: false },
      { name: '형산강1', code: '2101A10', isChecked: false },
      { name: '형산강2', code: '2101A20', isChecked: false },
      { name: '형산강3', code: '2101A30', isChecked: false },
      { name: '기계천', code: '2101A40', isChecked: false },
      { name: '형산강4', code: '2101A50', isChecked: false },
      { name: '형산강5', code: '2101A60', isChecked: false },
      { name: '형산강6', code: '2101A65', isChecked: false },
      { name: '칠성천', code: '2101A70', isChecked: false },
      { name: '왕피천', code: '2101A80', isChecked: false },
      { name: '방천1', code: '2102A10', isChecked: false },
      { name: '방천2', code: '2102A20', isChecked: false },
    ],
  },
  {
    name: '경상남도',
    subItems: [
      { name: '가야천', code: '2013A20', isChecked: false },
      { name: '창녕(적포)', code: '2014A60', isChecked: false },
      { name: '덕곡', code: '2014A70', isChecked: false },
      { name: '거창동천1', code: '2015A10', isChecked: false },
      { name: '거창동천2', code: '2015A20', isChecked: false },
      { name: '거창위천1', code: '2015A30', isChecked: false },
      { name: '거창위천2', code: '2015A40', isChecked: false },
      { name: '황강1(황강A)', code: '2015A50', isChecked: false },
      { name: '황강2', code: '2015A60', isChecked: false },
      { name: '가천', code: '2015A70', isChecked: false },
      { name: '황강4', code: '2016A10', isChecked: false },
      { name: '황강5', code: '2016A20', isChecked: false },
      { name: '황강6(황강B)', code: '2016A30', isChecked: false },
      { name: '합천', code: '2017A10', isChecked: false },
      { name: '신반천', code: '2017A20', isChecked: false },
      { name: '토평천1', code: '2017A30', isChecked: false },
      { name: '토평천2', code: '2017A40', isChecked: false },
      { name: '창녕천', code: '2017A50', isChecked: false },
      { name: '용산(낙본H)', code: '2017A60', isChecked: false },
      { name: '남강천', code: '2018A05', isChecked: false },
      { name: '함양위천1', code: '2018A10', isChecked: false },
      { name: '함양위천2', code: '2018A15', isChecked: false },
      { name: '임천(남강A2)', code: '2018A29', isChecked: false },
      { name: '경호강1(남강A)', code: '2018A35', isChecked: false },
      { name: '양천2', code: '2018A40', isChecked: false },
      { name: '경호강2(남강B)', code: '2018A45', isChecked: false },
      { name: '덕천강1', code: '2018A50', isChecked: false },
      { name: '덕천강2', code: '2018A55', isChecked: false },
      { name: '남강2', code: '2019A10', isChecked: false },
      { name: '영천강2', code: '2019A20', isChecked: false },
      { name: '남강3', code: '2019A30', isChecked: false },
      { name: '남강5', code: '2019A40', isChecked: false },
      { name: '의령천', code: '2019A50', isChecked: false },
      { name: '함안천1', code: '2019A60', isChecked: false },
      { name: '함안천2', code: '2019A70', isChecked: false },
      { name: '남강6(남강)', code: '2019A75', isChecked: false },
      { name: '남강7(남강E)', code: '2019A80', isChecked: false },
      { name: '남지', code: '2020A10', isChecked: false },
      { name: '계성천', code: '2020A15', isChecked: false },
      { name: '광려천1', code: '2020A20', isChecked: false },
      { name: '광려천3', code: '2020A25', isChecked: false },
      { name: '광려천4', code: '2020A30', isChecked: false },
      { name: '함안', code: '2020A32', isChecked: false },
      { name: '임해진', code: '2020A35', isChecked: false },
      { name: '청도천2', code: '2020A40', isChecked: false },
      { name: '하남', code: '2020A45', isChecked: false },
      { name: '화포천', code: '2020A50', isChecked: false },
      { name: '삼랑진2', code: '2020A60', isChecked: false },
      { name: '단장천2', code: '2021A30', isChecked: false },
      { name: '밀양강2', code: '2021A40', isChecked: false },
      { name: '밀양강3', code: '2021A50', isChecked: false },
      { name: '밀양강4(밀양B, 밀양)', code: '2021A60', isChecked: false },
      { name: '물금(낙본K)', code: '2022A10', isChecked: false },
      { name: '양산천1', code: '2022A15', isChecked: false },
      { name: '양산천2', code: '2022A20', isChecked: false },
      { name: '양산천3', code: '2022A25', isChecked: false },
      { name: '덕계천', code: '2301A10', isChecked: false },
      { name: '백련천', code: '2501A10', isChecked: false },
      { name: '곤양천', code: '2501A20', isChecked: false },
      { name: '사천천', code: '2501A30', isChecked: false },
      { name: '고성천', code: '2501A40', isChecked: false },
      { name: '남해봉천', code: '2502A10', isChecked: false },
      { name: '연초천1', code: '2503A10', isChecked: false },
      { name: '연초천2', code: '2503A20', isChecked: false },
      { name: '구천천', code: '2503A30', isChecked: false },
      { name: '창원천', code: '2504A10', isChecked: false },
      { name: '내동천', code: '2504A20', isChecked: false },
      { name: '산호천', code: '2504A27', isChecked: false },
      { name: '진전천', code: '2504A35', isChecked: false },
      { name: '덕은', code: '4009A40', isChecked: false },
      { name: '하동', code: '4009A50', isChecked: false },
      { name: '횡천강1', code: '4009A60', isChecked: false },
      { name: '횡천강2', code: '4009A70', isChecked: false },
      { name: '횡천강3', code: '4009A80', isChecked: false },
      { name: '황강3(황강B2)', code: '2015A80', isChecked: false },
      { name: '사천천(황강B1)', code: '2015A90', isChecked: false },
      { name: '오봉천(남강A1)', code: '2018A27', isChecked: false },
      { name: '신등천(남강B2)', code: '2018A36', isChecked: false },
      { name: '양천1(남강B3)', code: '2018A38', isChecked: false },
      { name: '미곡천(남강B1)', code: '2018A39', isChecked: false },
      { name: '덕천강3(남강C1)', code: '2018A60', isChecked: false },
      { name: '남강1(남강C)', code: '2019A05', isChecked: false },
      { name: '영천강1(남강D1)', code: '2019A15', isChecked: false },
      { name: '남강4(남강D)', code: '2019A35', isChecked: false },
      { name: '광려천2(남강E1)', code: '2020A10', isChecked: false },
      { name: '함안1', code: '2020A15', isChecked: false },
      { name: '계성천2(남강F)', code: '2021A10', isChecked: false },
    ],
  },
  {
    name: '제주특별자치도',
    subItems: [
      { name: '옹포천', code: '6001A10', isChecked: false },
      { name: '외도천', code: '6002A10', isChecked: false },
      { name: '동홍천', code: '6003A10', isChecked: false },
      { name: "천미천", code: "6004A10", isChecked: false }
    ],
  },
];
