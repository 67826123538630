import React, { useEffect, useState, useRef } from 'react';
import { Paper, Typography, Button, TextField } from '@mui/material';
import { customAxios } from '../../../../Common/CustomAxios';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { v4 as uuidv4 } from 'uuid';
import DataInChartModal from '../../dataInChartStep/DataInChartModal';
import { useNavigate } from 'react-router-dom';
import usePhotoStore from '../../../../Data/DataInChart/store/photoStore';
import axios from 'axios';
import '../../../createClass/customContainer.css';
import { convertToNumber } from '../../../../Data/DataInChart/store/utils/convertToNumber';

//항목 이름 (한국어 -> 영어)
const engToKor = (name) => {
  const kor = {
    ITEMDATE: '측정일',

    //수질 데이터
    PTNM: '조사지점명',
    ITEMWMWK: '회차',
    ITEMWNDEP: '수심',
    ITEMTEMP: '수온',
    ITEMDO: '용존 산소',
    ITEMBOD: 'BOD',
    ITEMCOD: 'COD',
    ITEMSS: '부유물',
    ITEMTN: '총 질소',
    ITEMTP: '총인',
    ITEMTOC: '총유기탄소',

    //대기질 데이터
    stationName: '조사지점명',
    ITEMNO2: '산소 농도(ppm)',
    ITEMO3: '오존 농도(ppm)',
    ITEMPM10: '미세먼지(PM10) 농도(㎍/㎥)',
    ITEMPM25: '미세먼지(PM2.5) 농도(㎍/㎥)',
    ITEMSO2VALUE: '아황산가스 농도(ppm)',

    //시도별 대기질 데이터
    ITEMCODE: '변인',
    ITEMDATETIME: '측정 시간',
    ITEMDAEGU: '대구',
    ITEMCHUNGNAM: '충남',
    ITEMINCHEON: '인천',
    ITEMDAEJEON: '대전',
    ITEMGYONGBUK: '경북',
    ITEMSEJONG: '세종',
    ITEMGWANGJU: '광주',
    ITEMJEONBUK: '전북',
    ITEMGANGWON: '강원',
    ITEMULSAN: '울산',
    ITEMJEONNAM: '전남',
    ITEMSEOUL: '서울',
    ITEMBUSAN: '부산',
    ITEMJEJU: '제주',
    ITEMCHUNGBUK: '충북',
    ITEMGYEONGNAM: '경남',
    ITEMGYEONGGI: '경기',

    //SEED 데이터
    measuredDate: '측정 시간',
    location: '측정 장소',
    unit: '소속',
    period: '저장 주기',
    username: '사용자명',
    hum: '습도',
    temp: '기온',
    tur: '탁도',
    ph: 'pH',
    dust: '미세먼지',
    dox: '용존산소량',
    co2: '이산화탄소',
    lux: '조도',
    hum_EARTH: '토양 습도',
    pre: '기압',
  };
  return kor[name] || name;
};

function StudentRenderAssign({
  eclassName,
  tableData,
  assginmentCheck,
  stepCount,
  studentId,
  sessionIdState,
  eclassUuid,
  allData,
  localStoredPhotoList,
  setLocalStoredPhotoList,
  eClassUuid,
  openReportModal,
  handleCloseModal,
  textBoxDatas,
  setTextBoxDatas,
}) {
  console.log(localStoredPhotoList);
  const [textBoxValues, setTextBoxValues] = useState({});
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 열기 상태 추가
  const navigate = useNavigate();
  const uploadedImagesState = useRef([]); // 업로드된 이미지 상태
  // console.log(textBoxDatas);
  const [imageUrlArray, setImageUrlArray] = useState([]);
  console.log(data, stepCount);
  console.log(eclassName);
  // Zustand store에서 getStorePhotoList 가져오기
  const { getStorePhotoList, setStorePhotoList } = usePhotoStore();

  useEffect(() => {
    const photoList = getStorePhotoList();
    if (photoList) {
      // console.log('사진 수 : ', getStorePhotoList().length);

      setLocalStoredPhotoList(photoList);
    }
  }, []);

  // console.log(data);
  const handleSubmit2 = async () => {
    const studentName = localStorage.getItem('username');
    const reportUuid = uuidv4();

    const groupedContents = data.map((data, stepIndex) => ({
      contentName: data.contentName, // data에서 contentName 가져오기
      stepNum: data.stepNum, // data에서 stepNum 가져오기
      contents: data.contents.map((contentItem, contentIndex) => {
        if (contentItem.type === 'img') {
          return {
            type: 'img',
            content: contentItem.content.file,
          };
        }
        if (contentItem.type === 'textBox') {
          return {
            ...contentItem,
            content: {
              text:
                textBoxDatas[contentItem.content.uuid] === undefined
                  ? contentItem.content.text
                  : textBoxDatas[contentItem.content.uuid],
              uuid: contentItem.content.uuid,
            },
          };
        } else if (contentItem.type === 'dataInChartButton') {
          // console.log(contentItem);
          console.log(contentItem);
          if (contentItem.content.photoList !== undefined)
            return {
              type: 'dataInChartButton',
              content: {
                photoList: [
                  ...localStoredPhotoList
                    .filter(
                      (photoObj) => photoObj.id === contentItem.content.id,
                    )
                    .map((photo) => photo.photo.image),
                  ...contentItem.content.photoList,
                ],
                dataType: contentItem.content.dataType,
                id: contentItem.content.id,
              },
            };

          return {
            type: 'dataInChartButton',
            content: {
              photoList: [
                ...localStoredPhotoList
                  .filter((photoObj) => photoObj.id === contentItem.content.id)
                  .map((photo) => photo.photo.image),
              ],
              dataType: contentItem.content.dataType,
              id: contentItem.content.id,
            },
          };
        }
        return contentItem;
      }),
    }));

    console.log(groupedContents);
    const updatedData = [
      {
        uuid: reportUuid,
        timestamp: new Date().toISOString(),
        username: studentName,
        stepName: eclassName, // 고정된 값으로 설정
        stepCount: groupedContents.length,
        contents: groupedContents,
      },
    ];

    try {
      // console.log(
      //   '업데이트 하기전 확인 : ' + JSON.stringify(updatedData, null, 2),
      // );
      // console.log(
      //   '업데이트 하기전 확인 assginmentCheck : ' +
      //     JSON.stringify(assginmentCheck, null, 2),
      // );

      const requestData = {
        reportUuid: reportUuid,
        studentId: studentId,
      };

      await customAxios.post(
        '/api/eclass/student/assignment/report/save',
        requestData,
      );

      if (assginmentCheck) {
        await customAxios.put('/api/report/update', updatedData);
      } else {
        await customAxios.post('/api/report/save', updatedData);
      }

      // window.location.reload();
    } catch (error) {
      console.error('오류가 발생했습니다: ', error);
      alert('제출에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  const handleNavigate = async (dataType, uuid, stepNum) => {
    await handleSubmit2();

    const id = 'drawGraph';
    // alert(stepNum);
    localStorage.setItem('stepNum', stepNum);
    if (dataType === null && uuid === null) {
      navigate(
        `/data-in-chart?id=${id}&eclassUuid=${eClassUuid}&eclassName=${eclassName}`,
      );
    } else
      navigate(
        `/data-in-chart?id=${id}&dataType=${dataType}&uuid=${uuid}&eclassUuid=${eClassUuid}&eclassName=${eclassName}`,
      );
  };

  useEffect(() => {
    let dataToUse = tableData;
    // console.log(tableData);

    // console.log('dataToUse:', JSON.stringify(dataToUse, null, 2));

    // const parseStepCount = parseInt(stepCount, 10); // 10진수로 파싱

    // tableData에서 stepNum과 parseStepCount가 같은 항목 필터링
    // let filteredData = dataToUse.filter(
    //   (data) => data.stepNum === parseStepCount,
    // );

    let filteredData = dataToUse;

    // console.log(filteredData);
    // console.log('Filtered Data:', filteredData);

    // 상태에 필터링된 데이터 세팅
    console.log(filteredData);
    const newSteps = [];
    const fetchData = async () => {
      // console.log(filteredData);
      let newStep;
      for (let i = 0; i < filteredData.length; i++) {
        const curData = filteredData[i];
        newStep = {
          contentName: curData.contentName,
          stepNum: curData.stepNum,
          contents: [],
        };

        for (let j = 0; j < curData.contents.length; j++) {
          const content = curData.contents[j];

          if (content.type === 'html') {
            newStep.contents.push(content);
          } else if (content.type === 'textBox') {
            newStep.contents.push({
              type: 'textBox',
              content: {
                text: content.content.text,
                uuid: content.content.uuid,
              },
            });
          } else if (content.type === 'img') {
            newStep.contents.push({
              type: 'img',
              content: {
                file: content.content,
              },
            });
          } else if (content.type === 'data') {
            let tableContent;
            let dataContent;
            // console.log(content.content.type);
            if (content.content.type === '커스텀 데이터') {
              await customAxios
                .get(`api/custom/${content.content.id}`)
                .then((res) => {
                  //수정 필요

                  const title = res.data.title;
                  let rows = 0;
                  let columns = 0;
                  const headerSet = new Set();
                  res.data.numericFields.forEach((table) => {
                    const key = Object.keys(table)[0];
                    headerSet.add(key);
                  });

                  res.data.stringFields.forEach((table) => {
                    const key = Object.keys(table)[0];
                    headerSet.add(key);
                  });

                  columns = headerSet.size;
                  rows =
                    (res.data.numericFields.length +
                      res.data.stringFields.length) /
                    columns;
                  const variables = Array(columns);

                  const data = Array(rows + 1)
                    .fill()
                    .map(() => Array(columns).fill(0));

                  res.data.numericFields.forEach((table) => {
                    const key = Object.keys(table)[0];
                    if (table[key].order < columns) {
                      data[0][table[key].order] = key;
                      variables[table[key].order] = {
                        name: key,
                        type: 'Numeric',
                        isSelected: false,
                        isMoreSelected: false,
                        variableIndex: table[key].order,
                      };
                    }

                    data[Math.floor(table[key].order / columns) + 1][
                      table[key].order % columns
                    ] = convertToNumber(table[key].value);
                  });

                  res.data.stringFields.forEach((table) => {
                    const key = Object.keys(table)[0];
                    if (table[key].order < columns) {
                      data[0][table[key].order] = key;
                      variables[table[key].order] = {
                        name: key,
                        type: 'Categorical',
                        isSelected: false,
                        isMoreSelected: false,
                        variableIndex: table[key].order,
                      };
                    }
                    data[Math.floor(table[key].order / columns) + 1][
                      table[key].order % columns
                    ] = convertToNumber(table[key].value);
                  });
                  // console.log(data);
                  dataContent = data;

                  // localStorage.setItem('data', JSON.stringify(data));
                  // localStorage.setItem('title', JSON.stringify(title));

                  // JSON 데이터를 테이블 형식으로 변환하여 contents에 추가
                  let headers = dataContent[0];

                  tableContent = (
                    <div style={{ width: 'auto', overflowX: 'auto' }}>
                      <div
                        style={{
                          transform: 'scale(1)',
                          transformOrigin: 'top left',
                        }}
                      >
                        <table
                          style={{
                            width: '100%',
                            marginTop: '10px',
                            borderCollapse: 'collapse',
                            tableLayout: 'fixed', // 테이블 셀 너비를 고정
                          }}
                        >
                          <thead>
                            <tr>
                              {headers.map((header) => (
                                <th
                                  key={header}
                                  style={{
                                    border: '1px solid #ddd',
                                    padding: '8px',
                                    backgroundColor: '#f2f2f2',
                                    wordWrap: 'break-word', // 긴 단어를 줄바꿈
                                    fontSize: '10px', // 테이블 길이에 맞춰서 size 조절 수정해야함
                                  }}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {dataContent.map((row, rowIndex) => (
                              <tr
                                key={rowIndex}
                                style={{ borderBottom: '1px solid #ddd' }}
                              >
                                {row.map((item) => (
                                  <td
                                    key={`${rowIndex}-${item}`}
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px',
                                      wordWrap: 'break-word', // 긴 단어를 줄바꿈
                                      fontSize: '10px', // 테이블 길이에 맞춰서 size 조절 수정해야함
                                    }}
                                  >
                                    {item}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })
                .catch((err) => console.log(err));
            } else {
              let headers = [];
              let path = '';

              if (content.content.type === '수질 데이터') {
                path = `/ocean-quality/mine/chunk?dataUUID=${content.content.id}`;
                await customAxios
                  .get(path, {
                    headers: {
                      userName: localStorage.getItem('username'),
                    },
                  })
                  .then((res) => {
                    // 남기고 싶은 키 목록
                    const keysToKeep = [
                      'PTNM',
                      'ITEMDATE',
                      'ITEMWMWK',
                      'ITEMWNDEP',
                      'ITEMBOD',
                      'ITEMCOD',
                      'ITEMDO',
                      'ITEMSS',
                      'ITEMTEMP',
                      'ITEMTN',
                      'ITEMTOC',
                      'ITEMTP',
                    ];

                    // 변환 로직
                    const transformedData = res.data[0].data.map((item) => {
                      const newItem = {};
                      keysToKeep.forEach((key) => {
                        if (item[key] !== undefined) {
                          if (item[key] === null) return;
                          else if (isNaN(item[key])) newItem[key] = item[key];
                          else newItem[key] = Number(item[key]);
                        } else {
                          newItem[key] = null; // 해당 키가 없으면 null로 설정
                        }
                      });
                      return newItem;
                    });
                    // console.log(transformedData);
                    headers = Object.keys(transformedData[0]);

                    headers = headers.map((header) => engToKor(header));

                    dataContent = transformedData.map((item) =>
                      Object.values(item),
                    );
                    // 최종 결과 생성 (헤더 + 값)
                    const recombined = [headers, ...dataContent];
                    // console.log(recombined);
                  })
                  .catch((err) => console.log(err));
              } else if (content.content.type === '대기질 데이터') {
                path = `/air-quality/mine/chunk?dataUUID=${content.content.id}`;
                await customAxios
                  .get(path, {
                    headers: {
                      userName: localStorage.getItem('username'),
                    },
                  })
                  .then((res) => {
                    // 남기고 싶은 키 목록
                    const keysToKeep = [
                      'stationName',
                      'ITEMDATE',
                      'ITEMNO2',
                      'ITEMO3',
                      'ITEMPM10',
                      'ITEMPM25',
                      'ITEMSO2VALUE',
                    ];
                    // console.log(res.data);

                    // 변환 로직
                    const transformedData = res.data.data.map((item) => {
                      const newItem = {};
                      keysToKeep.forEach((key) => {
                        if (item[key] !== undefined) {
                          if (item[key] === null) return;
                          else if (isNaN(item[key])) newItem[key] = item[key];
                          else newItem[key] = Number(item[key]);
                        } else {
                          newItem[key] = null; // 해당 키가 없으면 null로 설정
                        }
                      });
                      return newItem;
                    });
                    // console.log(transformedData);
                    headers = Object.keys(transformedData[0]);

                    headers = headers.map((header) => engToKor(header));

                    dataContent = transformedData.map((item) =>
                      Object.values(item),
                    );
                    // 최종 결과 생성 (헤더 + 값)
                    const recombined = [headers, ...dataContent];
                    // console.log(recombined);
                  })
                  .catch((err) => console.log(err));
              } else if (content.content.type === '시도별 대기질 데이터') {
                path = `/city-air-quality/mine/chunk?dataUUID=${content.content.id}`;
                await customAxios
                  .get(path, {
                    headers: {
                      userName: localStorage.getItem('username'),
                    },
                  })
                  .then((res) => {
                    // 남기고 싶은 키 목록
                    const keysToKeep = [
                      'ITEMCODE',
                      'ITEMDATETIME',
                      'ITEMDAEGU',
                      'ITEMCHUNGNAM',
                      'ITEMINCHEON',
                      'ITEMDAEJEON',
                      'ITEMGYONGBUK',
                      'ITEMSEJONG',
                      'ITEMGWANGJU',
                      'ITEMJEONBUK',
                      'ITEMGANGWON',
                      'ITEMULSAN',
                      'ITEMJEONNAM',
                      'ITEMSEOUL',
                      'ITEMBUSAN',
                      'ITEMJEJU',
                      'ITEMCHUNGBUK',
                      'ITEMGYEONGNAM',
                      'ITEMGYEONGGI',
                    ];
                    // console.log(res.data);

                    // 변환 로직
                    const transformedData = res.data.data.map((item) => {
                      const newItem = {};
                      keysToKeep.forEach((key) => {
                        if (item[key] === null) return;
                        else if (item[key] !== undefined) {
                          newItem[key] = item[key];
                        } else {
                          newItem[key] = null; // 해당 키가 없으면 null로 설정
                        }
                      });
                      return newItem;
                    });
                    // console.log(transformedData);
                    headers = Object.keys(transformedData[0]);

                    headers = headers.map((header) => engToKor(header));

                    dataContent = transformedData.map((item) =>
                      Object.values(item),
                    );
                    // 최종 결과 생성 (헤더 + 값)
                    const recombined = [headers, ...dataContent];
                    // console.log(recombined);
                  })
                  .catch((err) => console.log(err));
              }
              // console.log(dataContent);
              tableContent = (
                <div style={{ width: 'auto', overflowX: 'auto' }}>
                  <div
                    style={{
                      transform: 'scale(1)',
                      transformOrigin: 'top left',
                    }}
                  >
                    <table
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        borderCollapse: 'collapse',
                        tableLayout: 'fixed', // 테이블 셀 너비를 고정
                      }}
                    >
                      <thead>
                        <tr>
                          {headers.map((header) => (
                            <th
                              key={header}
                              style={{
                                border: '1px solid #ddd',
                                padding: '8px',
                                backgroundColor: '#f2f2f2',
                                wordWrap: 'break-word', // 긴 단어를 줄바꿈
                                fontSize: '10px', // 테이블 길이에 맞춰서 size 조절 수정해야함
                              }}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataContent?.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            style={{ borderBottom: '1px solid #ddd' }}
                          >
                            {headers.map((header, index) => (
                              <td
                                key={`${rowIndex}-${header}`}
                                style={{
                                  border: '1px solid #ddd',
                                  padding: '8px',
                                  wordWrap: 'break-word', // 긴 단어를 줄바꿈
                                  fontSize: '10px', // 테이블 길이에 맞춰서 size 조절 수정해야함
                                }}
                              >
                                {row[index]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            }

            newStep.contents.push({
              type: 'data',
              content: {
                view: tableContent,
                id: content.content.id,
                type: content.content.type,
              },
            });
          } else {
            newStep.contents.push(content);
          }
        }
        newSteps.push(newStep);
      }
      console.log(newSteps);
      setData(newSteps);
    };
    fetchData();
  }, [stepCount, tableData]);

  // 로컬에서 이미지 삭제한 부분 보여주기 위한 훅
  useEffect(() => {
    // console.log('데이터 체크 : ' + JSON.stringify(data, null, 2));

    if (data && data.length > 0 && imageUrlArray.length > 0) {
      const removeImagesFromData = async (data, imageUrlArray) => {
        // 각 item에 대해 처리
        const updatedDataPromises = data.map(async (item) => {
          const updatedContents = await Promise.all(
            item.contents.map((contentItem) => {
              // 이미지 타입의 데이터를 처리
              if (contentItem.type === 'img') {
                // console.log(
                //   '삭제 이미지 : ' +
                //     JSON.stringify(contentItem.content, null, 2),
                // );
                // imageUrlArray에 있는 URL과 일치하는지 확인하고 제거
                if (imageUrlArray.includes(contentItem.content)) {
                  return null; // 이미지 삭제 시 null 반환
                }
              }
              return contentItem; // 다른 항목은 그대로 유지
            }),
          );

          // null 값을 필터링하여 업데이트된 데이터를 반환
          return {
            ...item,
            contents: updatedContents.filter(
              (contentItem) => contentItem !== null,
            ),
          };
        });

        const updatedData = await Promise.all(updatedDataPromises);

        // console.log(
        //   '업데이트 데이터 체크 : ' + JSON.stringify(updatedData, null, 2),
        // );

        return updatedData;
      };

      removeImagesFromData(data, imageUrlArray).then((updatedData) => {
        setData(updatedData);
      });
    }
  }, [imageUrlArray]);

  const handleTextBoxSubmit = (stepNum, index, text) => {
    setTextBoxValues((prev) => ({
      ...prev,
      [stepNum]: {
        ...(prev[stepNum] || []),
        [index]: text,
      },
    }));
  };

  // 이미지 파일 업로드 메서드
  const handleUpload = async (image, contentUuid) => {
    try {
      const response = await customAxios.get('/api/images/presigned-url', {
        params: { fileName: contentUuid },
      });
      const { preSignedUrl, imageUrl } = response.data;
      const contentType = 'image/jpg';

      await axios.put(preSignedUrl, image, {
        headers: {
          'Content-Type': contentType,
        },
      });

      return imageUrl;
    } catch (error) {
      console.error('파일 업로드 오류:', error);
      throw error;
    }
  };

  // 이미지 삭제 등으로 수정된 테이블 contents 교체 메서드
  const replaceContents = (tableData, data) => {
    // tableData의 각 항목을 순회하면서 contents를 교체
    const updatedTableData = tableData.map((tableItem) => {
      // tableItem의 contents 중에서 stepNum이 일치하는 항목을 찾음
      const updatedContents = tableItem.contents.map((contentItem) => {
        // data의 stepNum과 일치하는 contents 찾기
        const newContent = data.find(
          (dataItem) => dataItem.stepNum === contentItem.stepNum,
        );

        // 일치하는 stepNum의 contents가 있으면 해당 contents로 교체, 아니면 기존 내용 유지
        return newContent
          ? { ...contentItem, contents: newContent.contents }
          : contentItem;
      });

      // tableItem의 contents를 업데이트된 내용으로 교체
      return {
        ...tableItem,
        contents: updatedContents,
      };
    });

    return updatedTableData;
  };

  if (data.length === 0) return <div>로딩중</div>;

  // console.log('여기', data);
  return (
    <div className="relative">
      <button
        onClick={async () => {
          await handleSubmit2();
          alert('제출 완료');
          window.location.reload();
        }}
        style={{
          position: 'absolute',
          left: '48px',
          bottom: '-58px',
          padding: '8px 24px',
          backgroundColor: '#3b82f6', // 단색 배경 (파란색)
          color: 'white',
          fontWeight: '600',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // 그림자 효과
          cursor: 'pointer',
          transition: 'all 0.3s ease-in-out',
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = '#2563eb'; // 호버 시 더 진한 파란색
          e.target.style.boxShadow = '0px 6px 8px rgba(0, 0, 0, 0.15)'; // 호버 시 더 강한 그림자
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#3b82f6'; // 기본 배경색 복원
          e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)'; // 기본 그림자 복원
        }}
      >
        스텝 제출
      </button>
      <React.Fragment key={data[[Number(stepCount) - 1]].stepNum}>
        <Paper
          style={{
            padding: 20,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            width: '100%',
            height: '510px',
            overflow: 'auto',
          }}
          className="custom-html-container ql-editor"
        >
          <div>
            <Typography variant="h4" gutterBottom>
              {data[[Number(stepCount) - 1]].contentName}
            </Typography>
            {data[Number(stepCount) - 1].contents.map((content, idx) => (
              <RenderContent
                key={`${data[[Number(stepCount) - 1]].stepNum}-${idx}`}
                content={content}
                textBoxValue={textBoxDatas}
                setTextBoxValue={setTextBoxDatas}
                contentIndex={idx}
                onOpenModal={() => setIsModalOpen(true)}
                onNavigate={handleNavigate}
                storedPhotoList={localStoredPhotoList}
                setStorePhotoList={setStorePhotoList}
                setLocalStoredPhotoList={setLocalStoredPhotoList}
                setImageUrlArray={setImageUrlArray}
                data={data}
                setData={setData}
                stepNum={parseInt(stepCount, 10)}
              />
            ))}
          </div>
        </Paper>
      </React.Fragment>

      {isModalOpen && <DataInChartModal isModalOpen={isModalOpen} />}
    </div>
  );
}

function RenderContent({
  content,
  textBoxValue,
  setTextBoxValue,
  onNavigate,
  contentIndex,
  storedPhotoList,
  setLocalStoredPhotoList,
  stepNum,
  data,
  setData,
}) {
  const [tableData, setTableData] = useState(null);

  // console.log(index, stepNum);
  // alert(stepNum);

  const handleTextChange = (e, uuid) => {
    setTextBoxValue((prev) => {
      const copied = { ...prev };
      // console.log(index, stepNum);
      copied[uuid] = e.target.value;
      return copied;
    });
  };

  const handleSelectData = async (id) => {
    try {
      const response = await customAxios.get(`api/custom/${id}`);
      const fetchedData = response.data;

      const formattedData = fetchedData.numericFields.map((field, index) => ({
        ...fetchedData.stringFields[index],
        ...field,
      }));

      setTableData(formattedData); // 테이블 데이터를 상태에 저장
    } catch (error) {
      console.error('데이터 가져오기 실패:', error);
    }
  };

  function tableRender(content) {
    if (typeof content === 'string' || typeof content === 'number') {
      return content;
    }

    const { type, props } = content;

    return React.createElement(
      type,
      { ...props, key: props.key || null, ref: props.ref || null },
      props.children
        ? Array.isArray(props.children)
          ? props.children.map((child) => tableRender(child))
          : tableRender(props.children)
        : null,
    );
  }
  console.log(content.content);
  console.log(content.content.file);
  switch (content.type) {
    case 'html':
      return <div dangerouslySetInnerHTML={{ __html: content.content }} />;
    case 'textBox':
      return (
        <textarea
          defaultValue={
            content.content.text == '답변을 이곳에 입력해주세요'
              ? ''
              : content.content.text
          }
          value={textBoxValue[content.content.uuid]}
          onChange={(e) => {
            handleTextChange(e, content.content.uuid);
          }}
          placeholder="답변을 입력해주세요"
          className="w-full p-4 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
          rows="3"
        />
      );
    case 'img':
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: `<img src=${content.content.file} />`,
          }}
        />
      );

    case 'dataInChartButton':
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                onNavigate(
                  content.content.dataType,
                  content.content.id,
                  stepNum,
                );
              }}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#6200ea',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '20px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                fontWeight: 'bold',
                fontSize: '1rem',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#3700b3',
                },
              }}
            >
              Data & Chart
            </Button>
          </div>

          <div
            style={{
              marginTop: '10px',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(600px, 1fr))',
              gap: '10px',
              justifyContent: 'center',
            }}
          >
            {storedPhotoList.length > 0 ? (
              storedPhotoList
                .filter((photoObj) => photoObj.id === content.content.id)
                .map((photo, index) => (
                  <div
                    key={photo.photo.image || index} // key를 photo.image로 설정, 없을 경우 index 사용
                    style={{
                      marginBottom: '20px',
                      position: 'relative',
                      textAlign: 'center',
                    }}
                  >
                    <img
                      src={photo.photo.image}
                      alt={photo.photo.title || `photo-${index}`} // 고유 alt 제공
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                    />
                    {/* 삭제 버튼 */}
                    <button
                      onClick={() => {
                        console.log(storedPhotoList);
                        console.log(index);
                        setLocalStoredPhotoList((prev) => {
                          const copiedLocalStoredPhotoList = prev.map(
                            (photo) => ({
                              id: photo.id,
                              photo: {
                                image: photo.photo.image,
                                title: photo.photo.title,
                              },
                            }),
                          );

                          let prevIndex = 0;
                          for (
                            let i = 0;
                            copiedLocalStoredPhotoList.length;
                            i++
                          ) {
                            if (
                              copiedLocalStoredPhotoList[i].id !==
                              content.content.id
                            )
                              prevIndex++;

                            copiedLocalStoredPhotoList.splice(
                              prevIndex + index,
                              1,
                            );
                            return copiedLocalStoredPhotoList;
                          }
                        });
                      }}
                      className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                    >
                      삭제
                    </button>
                  </div>
                ))
            ) : (
              <></>
            )}
            {content.content.photoList !== undefined &&
              content.content.photoList.map((item, index) => (
                <div key={index} className="relative">
                  {/* 이미지 렌더링 */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<img src="${item}" alt="Chart Image" class="w-full h-auto" />`,
                    }}
                  />

                  {/* 삭제 버튼 */}
                  <button
                    onClick={() => {
                      setData((prev) => {
                        const copiedData = prev.map((stepData) => ({
                          contentName: stepData.contentName,
                          stepNum: stepData.stepNum,
                          contents: [...stepData.contents],
                        }));

                        copiedData[stepNum - 1].contents[
                          contentIndex
                        ].content.photoList.splice(index, 1);
                        return copiedData;
                      });
                    }}
                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                  >
                    삭제
                  </button>
                </div>
              ))}
          </div>
        </div>
      );
    case 'data':
      return React.createElement(
        content.content.view.type,
        content.content.view.props,
      );

    default:
      return null;
  }
}

export default StudentRenderAssign;
